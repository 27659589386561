@import url('https://fonts.googleapis.com/css?family=Open+Sans|Montserrat|MuseoModerno');

body {
  margin: 0;
  font-family: 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Override Draft.js */
.public-DraftEditorPlaceholder-root, .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.public-DraftEditor-content {
  min-height: 100px;
}

.public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

/* NOTE - Add this block to blockquote */
.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}